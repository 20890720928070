import Link from 'next/link';
import Button from '../../components/shared/Button/index';
import { container, errorMessage } from '../../styles/error-pages.module.scss';

export default function Custom404() {
  return (
    <div className={container}>
      <img src='/images/404.svg' alt='404' />
      <div className={errorMessage}>
        <h2>Página não encontrada</h2>
        <p>
          A página que procura não existe ou está temporariamente indisponível.
          <br />
          Prima o botão para voltar atrás.
        </p>
        <Link href='/'>
          <a>
            <Button text='Voltar' type='text' />
          </a>
        </Link>
      </div>
    </div>
  );
}
